<template>
  <span class="flex gap-[5px]">
    <IconsStar
      v-for="index in [0, 1, 2, 3, 4]"
      :key="index"
      class="w-4 h-4"
      :class="[
        isAllOrange || parseInt(props.rating) > index
          ? 'fill-orange'
          : 'fill-gray-mud'
      ]"
    />
  </span>
</template>

<script setup>
const props = defineProps({
  rating: {
    type: Number,
    default: 0
  }
})

const isAllOrange = computed(() => props.rating === 0)
</script>
